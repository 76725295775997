@import "@Static/css/common";

.root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    // Custom scrollbars
    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                    "color": (
                        255,
                        91,
                        0,
                    ),
                ),
            )
        );
        border: 1px solid white;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: transparent;
    }
}

.laconicContainer {
    width: 100vw;
    flex-grow: 1;
    position: relative;
    background-color: #000000a5;
    display: flex;
    // overflow-y: overlay;
}
.background {
    // Default image
    background-image: url("https://images.pexels.com/photos/542556/pexels-photo-542556.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=640&w=480");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

.staticContainer {
    z-index: 2;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 50px 0 20px;
    flex-grow: 1;
    align-items: flex-start;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    padding-top: 84px;
    max-width: 50vw;
}

.textPrimary {
    line-height: 1.2em;
    font-weight: 600;
    padding-top: 25px;

    width: 500px;
    margin-left: auto;

    word-wrap: break-word;
}

.textSecondary {
    font-weight: 300;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: auto;
    width: 500px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.coverPreviewInfo {
    margin: 20px 0;
    font-size: 16px;
    color: #aaa;
}
.flexGrow {
    flex-grow: 1;
}
.iconContainer {
    display: flex;
    flex-direction: row;
    margin: auto 0 40px auto;

    width: 450px;
}
.icon {
    margin-right: 10px;
    font-size: 46px;
    color: #ccc;
    cursor: pointer;
    &:hover {
        color: #fff;
    }
}
.eventContainer {
    flex-grow: 1;
    box-sizing: content-box;
    padding: calc(140px + 84px) 20px 60px 20px;
    z-index: 4;
    height: fit-content;
    max-width: calc(
        50vw - 40px
    ); // Half of the screen minus margin to the right
}

@media (min-width: 769px) {
    .textPrimary {
        font-size: 42px;
    }
    .textSecondary {
        font-size: 23px;
        letter-spacing: 0.2px;
        font-weight: 500;
    }
    .eventContainer {
        // Show the event card in the center if only single event
        &.singleEvent {
            padding: 60px 50px 60px 20px;
            overflow-y: auto;
            height: min-content;
            margin: auto 0;
        }
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .laconicContainer {
        padding-top: 0;
    }
    .staticContainer {
        padding: 44px 20px 20px;
    }
    .eventContainer {
        padding: 140px 20px 40px;
    }
    .iconContainer {
        width: 400px;
    }
    .textPrimary {
        width: 380px;
    }
    .textSecondary {
        width: 380px;
    }
}
@media (max-width: 768px) {
    .laconicContainer {
        flex-direction: column;
        overflow-y: unset;
        text-align: center;
        &::before {
            position: fixed;
        }
    }
    .iconContainer {
        margin: 30px auto;
        width: auto;
        display: block;
    }
    .textPrimary {
        font-size: 40px;
        margin: auto;
        width: auto;
        -webkit-line-clamp: 5;
    }
    .textSecondary {
        font-size: 18px;
        font-weight: 300;
        margin: auto;
        width: auto;
        -webkit-line-clamp: 8;
    }
    .staticContainer {
        padding: calc(44px + 62px) 5vw 20px;
        display: block;
        position: relative;
        height: auto;
        max-width: 100%;
    }
    .icon {
        font-size: 48px;
    }
    .eventContainer {
        overflow-y: unset;
        padding: 20px;
        max-width: 100%;
    }
}
