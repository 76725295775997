@import "@Static/css/common";
$color: #f47354;

.root {
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 2,
                "color": (
                    255,
                    255,
                    255,
                ),
            ),
            "tc": (
                "key": 3,
                "color": (
                    0,
                    0,
                    0,
                ),
            ),
        )
    );

    padding: 20px;
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    box-shadow: 20px 20px #000;
    max-width: 737px;
    text-align: left;
    position: relative;
    border: 2.5px solid black;
    cursor: pointer;

    transition: 0.2s ease all;

    &:hover {
        transform: translateY(-4px) scale(1.01);
    }
}

.col {
    display: flex;
    flex-direction: column;
}

.left {
    @extend .col;
    align-items: center;
}
.duration {
    text-align: center;
    white-space: pre;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 20px;
    b {
        text-transform: uppercase;
        text-align: center;
        font-size: 18px;
        color: #072425;
        line-height: 1.4;
        width: 70px;
    }
    span {
        text-align: center;
        font-size: 16px;
        color: #555;
        line-height: 1.2;
    }
}
.shareButton {
    border: 2px solid #dadce0;
    line-height: 43px;
    width: 50px;
    height: 50px;
    font-size: 25px;
    border-radius: 100%;
    text-align: center;
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
                "color": "244, 115, 84",
            ),
            "boc": (
                "key": 1,
                "color": "244, 115, 84",
            ),
        )
    );
    font-weight: 700;
    margin-top: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease all;
    &:hover {
        @include colorCombinationXYZ(
            (
                "boc": (
                    "key": 1,
                    "opacity": 0.25,
                    "color": "244, 115, 84",
                ),
                "bgc": (
                    "key": 1,
                    "opacity": 0.25,
                    "color": "244, 115, 84",
                ),
            )
        );
    }
}
.center {
    flex-grow: 1;
    padding: 0 20px;
    @extend .col;
    overflow: hidden;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.25;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.text {
    margin: 5px 0 auto;
    color: #a3a3a3;
    max-width: fit-content;
    max-height: 4.5em; // Line height (1.5em) x 3 lines = 4.5 em

    overflow: hidden;
    overflow-wrap: break-word;
    position: relative;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.priceContainer {
    height: 3em;
    flex-direction: column;

    display: inline-flex;
    justify-content: center;
}

.price {
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
                "color": "244, 115, 84",
            ),
        )
    );
    max-width: fit-content;
    font-weight: 600;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;

    overflow: hidden;
    overflow-wrap: break-word;
    position: relative;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.right {
    min-width: 202px;
    width: 202px;
    @extend .col;
    margin-left: auto;
}
.cardImage {
    margin-bottom: 10px;
    height: 114px;
    width: 202px;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/scoot-staging-572e7.appspot.com/o/000000000000000000000_event_images%2Fcategory%2Fdefault%2Ftes.jpeg?alt=media&token=a4cb0783-5e41-4443-892f-7cafbdc9b201");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    // To hide the broken image icon and display the background
    text-indent: -10000px;
}
.scheduleButton {
    border: 2px solid;
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
                "color": "244, 115, 84",
            ),
            "bgc": (
                "key": 1,
                "opacity": 0.2,
                "color": "244, 115, 84",
            ),
            "boc": (
                "key": 1,
                "color": "244, 115, 84",
            ),
        )
    );

    margin-top: auto;
    padding: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    transition: 0.2s ease all;
    &:hover {
        @include colorCombinationXYZ(
            (
                "bgc": (
                    "key": 1,
                    "opacity": 0.4,
                ),
            )
        );
        text-decoration: none;
    }
}
.earlyBird {
    position: absolute;
    top: 4px;
    left: -10px;

    background-color: #111;
    color: white;
    font-size: 14px;
    padding: 5px 12px;
    z-index: 1000;
    &:after {
        position: absolute;
        width: 0;
        height: 0;
        border-top: 10px solid #333;
        border-left: 10px solid transparent;
        content: "";
        bottom: -10px;
        left: 0;
    }
}

.earlyBirdRoot {
    padding-top: 36px;
}

@media (max-width: 1024px) and (min-width: 450px) {
    .root {
        margin: 0 auto 30px;
        box-shadow: 12px 12px #000c;
        padding: 16px;
    }
    .earlyBirdRoot {
        padding-top: 36px;
    }
    .left {
        padding-right: 8px;
    }
    .center {
        text-align: left;
        padding: 0 6px;
    }
    .cardImage {
        height: 78px;
        width: 140px;
    }
    .right {
        width: 140px;
        min-width: 140px;
    }
    .earlyBird {
        top: 5px;
        padding: 2.5px 12px;
    }
    .text {
        -webkit-line-clamp: 3;
    }
    .title {
        -webkit-line-clamp: 2;
    }
    .price {
        font-size: 15px;
    }
}

@media (max-width: 450px) {
    .root {
        flex-direction: column;
        position: relative;
        padding: 212px 20px 0px;
        box-shadow: 12px 12px #000;
        max-width: 420px;
    }
    .right {
        width: 100%;
        margin-bottom: 20px;
    }
    .cardImage {
        height: 200px;
        width: 100.5%;
        position: absolute;
        top: -1px;
        left: -1px;
    }
    .center {
        width: 100%;
        padding: 0;
    }
    .left {
        flex-direction: row;
        width: 100%;
        align-items: center;
        padding-bottom: 20px;
        &.shrinkLeft {
            position: absolute;
            width: min-content;
            right: 20px;
            padding-bottom: 0;
            transform: translateY(-30px);
            z-index: 100;
            background-color: white;
            border-radius: 100%;
            .duration {
                display: none;
            }
        }
    }
    .shareButton {
        margin-left: auto;
        margin-top: 0;
    }
    .duration {
        padding-bottom: 0;
        b {
            font-size: 16px;
            text-align: left;
        }
        span {
            font-size: 16px;
        }
    }
    .title {
        font-size: 16px;
        -webkit-line-clamp: 2;
    }
    .text {
        font-size: 14px;
        -webkit-line-clamp: 3;
    }
    .price {
        font-size: 14px;
        padding-bottom: 10px;
    }
    .shareButton {
        width: 35px;
        height: 35px;
        font-size: 20px;
        line-height: 1.4;
        background-color: #fff;
        &.dragUp {
            transform: translateY(-31px);
            z-index: 20;
        }
    }
    .scheduleButton {
        font-size: 14px;
    }
    .earlyBird {
        top: 8px;
    }
}
